body {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #19202b; /* Replace #ffffff with the desired color */
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend-Regular";
  src: url("./assets/fonts/Lexend-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lexend-Bold";
  src: url("./assets/fonts/Lexend-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter.ttf") format("truetype");
}

h1 {
  font-family: "Lexend-Bold", sans-serif;
  text-align: center;
  /* font-size: 28pt; */
}

h2 {
  font-family: "Lexend-Regular", sans-serif;
  text-align: center;
  font-size: 15pt;
  margin-bottom: 50px;
}

h3,
h4,
h5,
h6 {
  font-family: "Lexend", sans-serif;
  text-align: center;
}
:root {
  --background-color: #ff0000;
  --navbar-color: rgb(19, 25, 35, 0.9);
  --blue-color: #215af5;
  --blue-color2: rgb(33, 90, 245, 0.1);
  --green-color: #00bf71;
  --yellow-color: #ecc30b;
  --red-color: #b80c09;
}
.navlink {
  outline: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent;
  font-size: 11pt;
}

.navlink:focus,
.navlink:active {
  outline: none; /* Assurez-vous que le contour est désactivé lors du focus ou de l'activation */
}
.navbar {
  background-color: var(--navbar-color);
  color: white;
}
a {
  color: white;
}

h3 {
  margin: 0;
}
.couplet p,
.text p,
li {
  margin: 4px;
  font-size: 13pt;
}

/* .navbar a.active {
  background-image: radial-gradient(var(--blue-color), var(--blue-color2));

  border-radius: 50%;
  
}

.navbar a.active::after {
  content: "";
  filter: blur(5px);
  
} */
